import http from "../http-common";
import authHeader from "./Auth-header";

class botasService {
  newEntrega(data) {
    return http.post("/newentrega", data, { headers: authHeader() });
  }
  getEntregas(data) {
    return http.post("/getentregas", data, { headers: authHeader() });
  }
  getMails(data) {
    return http.post("/getmails", data, { headers: authHeader() });
  }
  updMail(data) {
    return http.post("/nwmail", data, { headers: authHeader() });
  }
  getTallas(data) {
    return http.post("/tallas", data, { headers: authHeader() });
  }
  entregar(data) {
    return http.post("/entregar", data, { headers: authHeader() });
  }
  exportar() {
    return http.get("/export", { headers: authHeader() });
  }
}

export default new botasService();
